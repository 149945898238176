REDEARTH = {

  common : {
    init : function(){
      this.initCarousels();
    },
    finalize : function(){
    },
    
    initCarousels : function() {
      if($('.carousel').length > 0) {
        $('.carousel').slick({
          arrows: true,
          dots: false,
          infinite: true,
          autoplaySpeed: 7500,
          slidesToShow: 4,
          responsive: [
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        });
      }
    }

  }

}

// ----------------------------------------------------------------

UTIL = {

  fire : function(func,funcname, args){

    var namespace = REDEARTH;  // indicate your obj literal namespace here

    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] == 'function'){
      namespace[func][funcname](args);
    }

  },

  loadEvents : function(){

    var bodyId = document.body.id;

    // hit up common first.
    UTIL.fire('common');

    // do all the classes too.
    $.each(document.body.className.split(/\s+/),function(i,classnm){
      UTIL.fire(classnm);
      UTIL.fire(classnm,bodyId);
    });

    UTIL.fire('common','finalize');

  }

};

// kick it all off here 
$(document).ready(UTIL.loadEvents);
